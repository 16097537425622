import Cookie from "js-cookie";
import store from '@/store';

export default {
  auth(to, from, next) {
    const token = Cookie.get("_token");
    
    const status = store.state.user?.status;

    if (!token && to.path !== "/login") {
      return next("/login");
    }

    if (status === 0 && to.path !== "/planos") {
      return next("/planos");
    }

    next();
  },

  login(to, from, next) {
    const token = Cookie.get("_token");

    if (token) {
      next("/");
    } else {
      next();
    }
  },
};
