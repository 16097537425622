<template>
  <div>
    <Sidebar />
    <div class="content">
      <NavBar />
      <main>
        <div class="header">
          <div class="left">

            <h1>Financeiro</h1>
          </div>
        </div>

        <div v-if="role != 2" class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class='bx bx-receipt'></i>
              <h3>Extrato</h3>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Projeto</th>
                  <th>Data</th>
                  <th>Valor de Projeto</th>
                  <th>ART/TRT</th>
                  <th>Total</th>
                  <th>Valor Pago</th>
                  <th>Valor Devedor</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.id" @click="view()">
                  <td>{{ item.project_name }}</td>
                  <td>{{ formatDate(0, item.created_at) }}</td>
                  <td>{{ convertAmount(item.amount_project) }}</td>
                  <td>{{ convertAmount(item.amount_art_trt) }}</td>
                  <td>{{ convertAmount(item.amount_project + item.amount_art_trt) }}</td>
                  <td>{{ convertAmount(item.amount_paid) }}</td>

                  <td>{{ convertAmount((item.amount_project + item.amount_art_trt) - item.amount_paid) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="role == 2" class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class='bx bx-receipt'></i>
              <h3>Extrato</h3>
            </div>
            <div v-for="(item, index) in items" :key="index" class="accordion-item">
              <button @click="toggle(index)" class="accordion-header">
                {{ item.name }}
              </button>
              <div v-show="activeIndex === index" class="accordion-content">
                <table>
                  <thead>
                    <tr>
                      <th>Projeto</th>
                      <th>Data</th>
                      <th>Valor de Projeto</th>
                      <th>ART/TRT</th>
                      <th>Total</th>
                      <th>Valor Pago</th>
                      <th>Valor Devedor</th>
                    </tr>
                  </thead>
                  <tbody v-for="(monthData, month) in groupProjectsByMonth(item.projects)" :key="month">
                    <!-- Cabeçalho do mês -->
                    <tr class="title_finance">
                      <td @click="toggleMonth(index, month)">
                        {{ month }}
                      </td>
                      <td></td>
                      <td>{{ convertAmount(monthData.totalAmountProject) }}</td>
                      <td>{{ convertAmount(monthData.totalAmountArtTrt) }}</td>
                      <td>{{ convertAmount(monthData.totalSum) }}</td>
                      <td>{{ convertAmount(monthData.totalPaid) }}</td>
                      <td>{{ convertAmount(monthData.totalPending) }}</td>
                    </tr>

                    <!-- Projetos do mês -->
                    <tr v-show="activeMonth[index] === month" v-for="project in monthData.projects" :key="project.id"
                      @click="addFoundProject(item.name, project.project_name, project.id)">
                      <td>{{ project.project_name }}</td>
                      <td>{{ formatDate(0, project.created_at) }}</td>
                      <td>{{ convertAmount(project.amount_project) }}</td>
                      <td>{{ convertAmount(project.amount_art_trt) }}</td>
                      <td>{{ convertAmount((project.amount_project + project.amount_art_trt)) }}</td>
                      <td>{{ convertAmount(project.amount_paid) }}</td>
                      <td>{{ convertAmount((project.amount_project + project.amount_art_trt) - project.amount_paid) }}
                      </td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th>Valor de Projeto Total</th>
                      <th>ART/TRT Total</th>
                      <th>Saldo Total</th>
                      <th>Saldo Pago Total</th>
                      <th>Saldo Devedor Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th></th>
                      <th></th>
                      <td>{{ convertAmount(item.amount_project_total) }}</td>
                      <td>{{ convertAmount(item.amount_art_trt_total) }}</td>
                      <td>{{ convertAmount(item.amount_project_total + item.amount_art_trt_total) }}</td>
                      <td>{{ convertAmount(item.amount_paid_total) }}</td>
                      <td>{{ convertAmount((item.amount_project_total + item.amount_art_trt_total) -
                        item.amount_paid_total) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
        <div class="bottom-data">
          <div class="orders f">
            <table>
              <thead>
                <tr>
                  <th>Valor de Projeto Total</th>
                  <th>ART/TRT Total</th>
                  <th>Saldo Total</th>
                  <th>Saldo Pago Total</th>
                  <th>Saldo Devedor Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ convertAmount(amount_project) }}</td>
                  <td>{{ convertAmount(amount_art_trt) }}</td>
                  <td>{{ convertAmount(amount_project + amount_art_trt) }}</td>
                  <td>{{ convertAmount(amount_paid) }}</td>
                  <td>{{ convertAmount((amount_project + amount_art_trt) - amount_paid) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </div>

  </div>
</template>
<script>

import Cookie from 'js-cookie'
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/SideBar.vue";

export default {
  components: {
    NavBar,
    Sidebar,
  },
  data() {
    return {
      items: [],
      activeIndex: null,
      activeMonth: {},
      author: "",
      token: "",
      amount_project: 0,
      amount_art_trt: 0,
      amount_pending: 0,
      amount_paid: 0,
      role: this.$store.state.role
    };
  },
  methods: {
    load() {
      this.$http.get("user/extract/list", {
        headers: {
          "Authorization": "Bearer " + this.token
        },
      })
        .then((response) => {
          this.items = response.data.extract;

          this.amount_project = response.data.amount_project;
          this.amount_art_trt = response.data.amount_art_trt;
          this.amount_pending = response.data.amount_pending;
          this.amount_paid = response.data.amount_paid;

        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }

        });
    },
    toggle(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
    showAlert(type, msg) {
      var textMsg = "";

      if (msg.message) {


        if (typeof msg.errors === 'object' && msg.errors) {
          for (const key in msg.errors) {
            if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(', ')}<br>`;
            }
          }
        } else {
          textMsg = `${msg.message}`;
        }
      } else {
        textMsg = `${msg}`;
      }

      this.$swal({
        text: textMsg,
        icon: type,
        confirmButtonText: 'Confirmar',
        html: textMsg
      });
    },
    addFoundProject(integrator, projectName, projectId) {
      this.$swal({
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Enviar valor",
        input: "text",
        didOpen: () => {
          const input = this.$swal.getInput();
          input.addEventListener("input", (e) => {
            e.target.value = this.formatCurrency(e.target.value);
          });
        },
        html: `<strong>Integrador:</strong> ${integrator} 
              <p><strong>Projeto:</strong> ${projectName}
              <p><p>
              Adicionar Valor Pago`
      }).then((result) => {
        if (result.isConfirmed) {
          const formattedValue = result.value.replace(/\./g, "").replace(",", ".");

          let json = {
            amount: parseFloat(formattedValue),
            project: projectId
          }

          this.$http
            .post("user/projects/paid", json, {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer " + this.token
              },
            }).then((response) => {
              this.showAlert('success', response.data.message);
              this.load();
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                this.showAlert('error', error.response.data);
              } else {
                this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
              }
            });
        }
      });
    },
    formatCurrency(value) {
      if (!value) return "";
      value = value.replace(/\D/g, ""); // Remove qualquer coisa que não seja número
      value = (value / 100).toFixed(2) + ""; // Converte para decimal
      value = value.replace(".", ","); // Substitui ponto por vírgula
      return value.replace(/\B(?=(\d{3})+(?!\d))/g, "."); // Adiciona separadores de milhar
    },
    formatDate(type, date) {
      if (!date) {
        return '';
      }

      const dateFormat = new Date(date);

      if (type == 0) {
        return `${dateFormat.getDate().toString().padStart(2, '0')}/${(dateFormat.getMonth() + 1).toString().padStart(2, '0')}/${dateFormat.getFullYear()}`;
      } else {

        return `${dateFormat.getDate().toString().padStart(2, '0')}/${(dateFormat.getMonth() + 1).toString().padStart(2, '0')}/${dateFormat.getFullYear()} às ${dateFormat.getHours().toString().padStart(2, '0')}:${dateFormat.getMinutes().toString().padStart(2, '0')}`;
      }

    },
    getStatusClass(status) {
      switch (status) {
        case 1: // completed
          return 'status completed';
        case 0: // cancelled
          return 'status cancelled';
        default:
          return 'status pending';
      }
    },
    getStatusText(status) {
      switch (status) {
        case 1: // completed
          return 'Ativo';
        case 0: // pending
          return 'Inativo';
        default:
          return 'Desconhecido';
      }
    },
    convertAmount(value) {
      return parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    view(type, id) {
      if (type == 1) return this.$router.push('/projetos/solar/gerenciar/' + id);
      if (type == 2) return this.$router.push('/projetos/medicao-agrupada/gerenciar/' + id);
      if (type == 3) return this.$router.push('/projetos/eletrico-predial/gerenciar/' + id);
      if (type == 4) return this.$router.push('/projetos/subestacao-eletrica/gerenciar/' + id);
    },
    groupProjectsByMonth(projects) {
      const grouped = {};

      projects.forEach((project) => {
        if (!project.created_at) return;

        const date = new Date(project.created_at);
        const month = date.toLocaleString('default', { month: 'long', year: 'numeric' });

        if (!grouped[month]) {
          grouped[month] = {
            projects: [],
            totalAmountProject: 0,
            totalAmountArtTrt: 0,
            totalSum: 0,
            totalPaid: 0,
            totalPending: 0
          };
        }
        console.log(project)
        // Adiciona o projeto ao mês
        grouped[month].projects.push(project);

        // Soma os valores
        grouped[month].totalAmountProject += project.amount_project;
        grouped[month].totalAmountArtTrt += project.amount_art_trt;
        grouped[month].totalSum += project.amount_project + project.amount_art_trt;

        grouped[month].totalPaid += project.amount_paid;

        grouped[month].totalPending += (project.amount_project + project.amount_art_trt) - project.amount_paid;
      });

      return grouped;
    },
    toggleMonth(integratorIndex, month) {
      if (!this.activeMonth[integratorIndex]) {
        this.activeMonth[integratorIndex] = null;
      }

      this.activeMonth[integratorIndex] = this.activeMonth[integratorIndex] === month ? null : month;
    }
  },

  computed: {
    contentStyle() {
      return { "max-height": this.expanded ? "100px" : 0 };
    },
    infoStyle() {
      return { opacity: this.expanded ? 1 : 0 };
    }
  },
  created() {
    if (Cookie.get('_token')) {
      this.token = Cookie.get('_token')
    }

    this.load();
  },
};
</script>

<style scoped>
.accordion-item {
  margin-bottom: 10px;
}

.accordion-header {
  padding: 10px;
  cursor: pointer;
  border: none;
  border-radius: 14px;
  width: 100%;
  text-align: left;
}

.accordion-content {
  margin: 10px;
}
</style>