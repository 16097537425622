<template>
  <div>
    <Sidebar />
    <div class="content">
      <NavBar />
      <main>
        <div class="header">
          <div class="left">
            <h1>Projeto - Solar</h1>
          </div>
        </div>

        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class="bx bx-message-square-dots"></i>
              <h3>Listagem</h3>
              <router-link to="/projetos/solar/adicionar" data-bs-placement="right"
                tooltip-bs-title="Adicionar Projeto">
                <i class="bx bx-plus-circle icon-success"></i>
              </router-link>
            </div>
            <table v-if="role != 2">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nome</th>
                  <th>Criação</th>
                  <th>Situação</th>
                  <th>Pagamento</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="project in projects" :key="project.id">
                  <td>{{ project.id }}</td>
                  <td>{{ project.project_name }}</td>
                  <td>{{ formatDate(0, project.created_at) }} </td>
                  <td>
                    <span class="status" :class="getStatusClass(project.id_state)">
                      {{ getStatusText(project.id_state) }}
                    </span>
                  </td>
                  <td>
                    <span class="status" :class="statusPay(project.payment_status)">
                      {{ getStatusPay(project.payment_status) }}
                    </span>
                  </td>
                  <td class="actions-icon">
                    <router-link :to="{ name: 'project-manager', params: { projeto: project.id } }">
                      <span data-bs-toggle="tooltip" tooltip-bs-title="Ver detalhes do projeto"
                        class="bx bx-detail ml-5 mr-5 icon-primary">
                      </span>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-show="role == 2" v-for="(item, index) in projects" :key="index" class="accordion-item">
              <button @click="toggle(index)" class="accordion-header">
                {{ item.full_name }}
              </button>
              <div v-show="activeIndex === index" class="accordion-content">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nome</th>
                      <th>Criação</th>
                      <th>Situação</th>
                      <th>Pagamento</th>
                      <th>Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="project in item.listProject" :key="project.id">
                      <td>{{ project.id }}</td>
                      <td>{{ project.project_name }}</td>
                      <td>{{ formatDate(0, project.created_at) }} </td>
                      <td>
                        <span class="status" :class="getStatusClass(project.id_state)">
                          {{ getStatusText(project.id_state) }}
                        </span>
                      </td>
                      <td>
                        <span class="status" :class="statusPay(project.payment_status)">
                          {{ getStatusPay(project.payment_status) }}
                        </span>
                      </td>
                      <td class="actions-icon">
                        <router-link :to="{ name: 'project-manager', params: { projeto: project.id } }">
                          <span data-bs-toggle="tooltip" tooltip-bs-title="Ver detalhes do projeto"
                            class="bx bx-detail ml-5 mr-5 icon-primary">
                          </span>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import Cookie from 'js-cookie'
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/SideBar.vue";

export default {
  components: {
    NavBar,
    Sidebar
  },
  data() {
    return {
      projects: [],
      author: "",
      token: "",
      activeIndex: null,
      role: this.$store.state.role
    };
  },
  methods: {
    toggle(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
    load() {
      this.$http
        .get("user/projects/list/1", {
          headers: {
            "Authorization": "Bearer " + this.token
          },
        })
        .then((response) => {
          this.projects = response.data.projects;
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }

        });
    },
    showAlert(type, msg) {
      var textMsg = "";

      if (msg.message) {


        if (typeof msg.errors === 'object' && msg.errors) {
          for (const key in msg.errors) {
            if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(', ')}<br>`;
            }
          }
        } else {
          textMsg = `${msg.message}`;
        }
      } else {
        textMsg = `${msg}`;
      }

      this.$swal({
        text: textMsg,
        icon: type,
        confirmButtonText: 'Confirmar',
        html: textMsg
      });
    },
    formatDate(type, date) {
      if (!date) {
        return '';
      }

      const dateFormat = new Date(date);

      if (type == 0) {
        return `${dateFormat.getDate().toString().padStart(2, '0')}/${(dateFormat.getMonth() + 1).toString().padStart(2, '0')}/${dateFormat.getFullYear()}`;
      } else {

        return `${dateFormat.getDate().toString().padStart(2, '0')}/${(dateFormat.getMonth() + 1).toString().padStart(2, '0')}/${dateFormat.getFullYear()} às ${dateFormat.getHours().toString().padStart(2, '0')}:${dateFormat.getMinutes().toString().padStart(2, '0')}`;
      }

    },
    getStatusClass(status) {
      switch (status) {
        case 1:
          return 'dark-grey';
        case 2:
          return 'light-warning';
        case 3:
          return 'pending';
        case 4:
          return 'warning';
        case 5:
          return 'danger';
        case 6:
          return 'success';
        case 7:
        case 10:
          return 'primary';
        case 8:
          return 'completed';
        case 9:
          return 'cancelled';
      }
    },
    getTypeProject(status) {
      switch (status) {
        case '1':
          return 'Solar';
        case '2':
          return 'Medição Agrupada';
        case '3':
          return 'Eletrico Predial';
        case '4':
          return 'Subestação';
      }
    },
    getStatusText(status) {
      switch (status) {
        case 1:
          return 'Aberto';
        case 2:
          return 'Em progresso';
        case 3:
          return 'Protocolado';
        case 4:
          return 'Em Parecer de Acesso';
        case 5:
          return 'Obra em andamento';
        case 6:
          return 'Aprovado';
        case 7:
          return 'Vistoria solicitada';
        case 8:
          return 'Finalizado';
        case 9:
          return 'Cancelado';
        case 10:
          return 'Aguardando Solicitação de Vistoria'
      }
    },
    statusPay(status) {
      switch (status) {
        case 1:
          return 'warning';
        case 2:
          return 'light-warning';
        case 3:
          return 'warning';
        case 4:
          return 'light-warning';
        case 5:
          return 'pending';
        case 6:
          return 'success';
      }
    },
    getStatusPay(status) {
      switch (status) {
        case 1:
          return 'Aguardando Pagamento 1/2';
        case 2:
          return 'Pagamento Confirmado 1/2';
        case 3:
          return 'Aguardando pagamento RRT';
        case 4:
          return 'Pagamento RRT Confirmado';
        case 5:
          return 'Aguardando Pagamento 2/2';
        case 6:
          return 'Pago';
      }
    },

  },
  created() {
    if (Cookie.get('_token')) {

      this.token = Cookie.get('_token')
    }
    this.load();
  },
};
</script>

<style scoped>
.accordion-item {
  margin-bottom: 10px;
}

.accordion-header {
  padding: 10px;
  cursor: pointer;
  border: none;
  border-radius: 14px;
  width: 100%;
  text-align: left;
}

.accordion-content {
  margin: 10px;
}
</style>