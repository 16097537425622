<template>
  <div>
    <Sidebar />
    <div class="content">
      <NavBar />
      <main v-readonly="isReadonly">
        <div class="header">
          <div class="left">
            <h1>{{ this.project_name }}</h1>
            <ul class="breadcrumb">
              <li>Projeto - Subestação Elétrica</li>
            </ul>
          </div>
        </div>

        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class="bx bx-receipt"></i>
              <h3>Informações gerais</h3>
            </div>
            <div class="row">
              <div class="col-md-6 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nome do projeto
                  </label>
                  <input v-model="project_name" type="text" class="form-control" name="project_name"
                    placeholder="Digite o Nome do projeto" />
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Unidade Consumidora
                  </label>
                  <input v-model="consumer_unit" type="text" class="form-control" name="project_name"
                    placeholder="Informe a UC" />
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Será Empresa?
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="is_legal_entity" @change="legalEntity">
                      <option value="0">Não</option>
                      <option value="1">Sim</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-12" :class="{ 'col-md-6': !is_legal_entity, 'col-md-5': is_legal_entity }">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nome do titular da UC
                  </label>
                  <input v-model="uc_holder_name" type="text" class="form-control" name="uc_holder_name"
                    placeholder="Digite o Nome do projeto" />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    RG
                  </label>
                  <input v-model="rg" type="text" class="form-control" name="rg" placeholder="0123456" />
                </div>
              </div>
              <div v-if="is_legal_entity" class="col-md-4 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nome da Empresa
                  </label>
                  <input v-model="company_name" type="text" class="form-control" name="company_name"
                    placeholder="Razão Social" />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    {{ !is_legal_entity ? 'CPF' : 'CNPJ' }}
                  </label>
                  <input v-if="is_cpf" v-model="cpf_cnpj" type="text" class="form-control" name="cpf_cnpj"
                    placeholder="000.000.000.-00" @input="autoUser" v-maska="'###.###.###-##'" />
                  <input v-if="!is_cpf" v-model="cpf_cnpj" type="text" class="form-control" name="cpf_cnpj"
                    placeholder="01.001.001/0001-01" @input="autoUser" v-maska="'##.###.###/####-##'" />
                </div>
              </div>
              <div class="col-md-4 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    E-mail
                  </label>
                  <input v-model="email" type="email" class="form-control" name="email" readonly />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Telefone
                  </label>
                  <input v-maska="'(##) #####-####'" v-model="phone" class="form-label" />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    CEP
                  </label>
                  <input v-maska="'#####-###'" v-model="cep" class="form-label" @change="search" />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Cidade
                  </label>
                  <input v-model="city" type="text" class="form-control" name="city" placeholder="Aguardando..." />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Estado
                  </label>
                  <input v-model="state" type="text" class="form-control" name="state" placeholder="Aguardando..." />
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Logradouro
                  </label>
                  <input v-model="street" type="text" class="form-control" name="street" placeholder="Aguardando..." />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Bairro
                  </label>
                  <input v-model="neighborhood" type="text" class="form-control" name="neighborhood"
                    placeholder="Aguardando..." />
                </div>
              </div>
              <div class="col-md-2 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Numero
                  </label>
                  <input v-model="address_number" type="text" class="form-control" name="address_number"
                    placeholder="Ex: 10" />
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Complemento
                  </label>
                  <input v-model="complement" type="text" class="form-control" name="complement"
                    placeholder="Ex: Casa, APT, Sítio, etc" />
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Situação
                  </label>
                  <div class="input-group mb-1">
                    <select v-if="role == 2" class="form-control" v-model="status">
                      <option value="1">Aberto</option>
                      <option value="2">Em progresso</option>
                      <option value="3">Protocolado</option>
                      <option value="4">Em Parecer de Acesso</option>
                      <option value="5">Obra em andamento</option>
                      <option value="6">Aprovado</option>
                      <option value="7">Vistoria solicitada</option>
                      <option value="8">Finalizado</option>
                      <option value="9">Cancelado</option>
                      <option value="10">Aguardando Pagamento</option>
                    </select>
                    <input type="text" v-else :value="statusType(status)" disabled>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Pagamento
                  </label>
                  <div class="input-group mb-1">
                    <select v-if="role == 2" class="form-control" v-model="payment_status">
                      <option value="1">Aguardando Pagamento 1/2</option>
                      <option value="2">Pagamento Confirmado 1/2</option>
                      <option value="3">Aguardando Pagamento 2/2</option>
                      <option value="4">Pago</option>
                    </select>
                    <input type="text" v-else :value="getStatusPay(payment_status)" disabled>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12">
                <span class="form-label mb-3">
                  <h6 class="pl-10">Informação do Projeto</h6>
                </span>
              </div>

              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Potência da Subestação:
                  </label>
                  <input type="text" class="form-control" name="module_model" min="0" placeholder="Ex: 10W"
                    v-model="substation_power" />
                </div>
              </div>
              <div class="col-md-5 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Distancia entre subestação e ponto de conexão:
                  </label>
                  <input type="text" class="form-control" name="module_model" min="0" placeholder="Descreva a distância"
                    v-model="substation_connection_distance" />
                </div>
              </div>
            </div>
            <div v-if="role == 2" class="row mt-5">
              <div class="col-12">
                <span class="form-label mb-3">
                  <h6 class="pl-10">Financeiro</h6>
                </span>
              </div>

              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Qual será o valor do projeto?
                  </label>
                  <div class="input-group mb-1">
                    <money3 v-model="amount_project" v-bind="config"></money3>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Qual será o valor do ATR/TRT?
                  </label>
                  <div class="input-group mb-1">
                    <money3 v-model="amount_art_trt" v-bind="config"></money3>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Qual o valor pago?
                  </label>
                  <div class="input-group mb-1">
                    <money3 v-model="amount_paid" v-bind="config"></money3>
                  </div>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-input mt-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Observação
                  </label>
                  <div class="areatext" v-if="!isReadonly">
                    <textarea v-model="observation" placeholder="Descrição do Projeto (Opcional)"></textarea>
                  </div>
                  <div class="areatext areatext-div" v-if="isReadonly" v-html="observation"></div>
                </div>
              </div>
            </div>
            <div v-if="role == 2 || status == 1 || status == 6"
              class="w-100 d-flex align-items-center justify-content-between mt-5 gap-3">
              <div class="d-flex gap-2">
                <button v-if="role == 2" class="btn btn-danger" @click="deleteConfirm">
                  <span>Deletar</span>
                </button>
                <button v-if="status == 6 && role != 2" class="btn btn-primary btn-all" @click="updateStatus(1)">
                  <span>Solicitar Vistoria</span>
                </button>
                <button v-if="status == 7 && role == 2" class="btn btn-primary btn-all" @click="updateStatus(2)">
                  <span>Concluir Vistoria</span>
                </button>
              </div>
              <button class="btn btn-success" @click="update">
                <span>Editar Projeto</span>
              </button>
            </div>
            <div class="header mt-5">
              <i class="bx bx-file"></i>
              <h3>Lista de Anexos</h3>
            </div>
            <div v-if="role == 2 || status == 1" class="row mb-3">
              <div class="col-12">
                <span class="form-label ">
                  <h6 class="pl-10 mb-3">Adicionar Anexo</h6>
                </span>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nome
                  </label>
                  <div class="input-group mb-1">
                    <input v-model="anexo_name" type="text" class="form-control" name="anexo_name"
                      placeholder="Nome do arquivo" />
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-12 mb-3">
                <div class="form-input">
                  <label for="formGroupExampleInput" class="form-label">
                    Arquivo
                  </label>
                </div>
                <input readonly id="anexo" ref="anexo" type="file" class="form-control" name="anexo" />
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input">
                  <label for="formGroupExampleInput" class="form-label"></label>
                </div>
                <button class="btn btn-primary btn-all" @click="upload">
                  <span>Adicionar</span>
                </button>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Criação</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="attachment in attachments" :key="attachment.id">
                  <td>{{ (attachment.id_type == 12) ? attachment.attach_name : attachment.name }}</td>
                  <td>{{ formatDate(0, attachment.created_at) }}</td>
                  <td class="actions-icon">
                    <a target="_blank" :href="baseStorage + attachment.path" download>
                      <span data-bs-toggle="tooltip" tooltip-bs-title="Ver detalhes do projeto"
                        class="bx bx-detail ml-5 mr-5 icon-primary">
                      </span>
                    </a>
                    <span v-if="role == 2 || status == 1" data-bs-toggle="tooltip" tooltip-bs-title="Deletar documento"
                      class="bx bx-trash ml-5 mr-5 icon-primary" @click="deletarDoc(attachment.id)">
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class="bx bx-file"></i>
              <h3>Pagamento</h3>
            </div>
            <div v-if="role == 2 || status == 1" class="row mb-3">
              <div class="col-12">
                <span class="form-label ">
                  <h6 class="pl-10 mb-3">Adicionar Anexo</h6>
                </span>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nome
                  </label>
                  <div class="input-group mb-1">
                    <input v-model="anexo_name" type="text" class="form-control" name="anexo_name"
                      placeholder="Nome do arquivo" />
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-12 mb-3">
                <div class="form-input">
                  <label for="formGroupExampleInput" class="form-label">
                    Arquivo
                  </label>
                </div>
                <input readonly id="anexo" ref="anexo" type="file" class="form-control" name="anexo" />
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input">
                  <label for="formGroupExampleInput" class="form-label"></label>
                </div>
                <button class="btn btn-primary btn-all" @click="upload">
                  <span>Adicionar</span>
                </button>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Criação</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="attachment in attachments" :key="attachment.id">
                  <td>{{ (attachment.id_type == 12) ? attachment.attach_name : attachment.name }}</td>
                  <td>{{ formatDate(0, attachment.created_at) }}</td>
                  <td class="actions-icon">
                    <a target="_blank" :href="baseStorage + attachment.path" download>
                      <span data-bs-toggle="tooltip" tooltip-bs-title="Ver detalhes do projeto"
                        class="bx bx-detail ml-5 mr-5 icon-primary">
                      </span>
                    </a>
                    <span v-if="role == 2 || status == 1" data-bs-toggle="tooltip" tooltip-bs-title="Deletar documento"
                      class="bx bx-trash ml-5 mr-5 icon-primary" @click="deletarDoc(attachment.id)">
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </div>

  </div>
</template>

<script>
import Cookie from 'js-cookie'
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/SideBar.vue";

export default {
  components: {
    NavBar,
    Sidebar
  },
  data() {
    return {
      role: this.$store.state.role,
      isReadonly: (this.$store.state.role == 2) ? false : true,
      project_name: '',
      consumer_unit: '',
      uc_holder_name: '',
      email: '',
      cpf_cnpj: '',
      rg: '',
      phone: '',
      street: '',
      address_number: '',
      neighborhood: '',
      city: '',
      state: '',
      payment_status: 1,
      cep: '',
      company_name: '',
      observation: '',
      has_attachment: '',
      complement: '',
      type: 1,
      is_legal_entity: 0,
      status: 0,
      is_cpf: true,
      token: Cookie.get('_token'),

      anexo_name: '',

      amount_project: 0,
      amount_art_trt: 0,
      amount_paid: 0,

      substation_power: '',
      substation_connection_distance: '',

      attachments: [],
      config: {
        masked: false,
        prefix: 'R$ ',
        suffix: '',
        thousands: '.',
        decimal: ',',
        precision: 2,
        disableNegative: false,
        disabled: (this.$store.state.role == 2) ? false : true,
        min: null,
        max: null,
        allowBlank: false,
        minimumNumberOfCharacters: 0,
        shouldRound: true,
        focusOnRight: false,
      }

    };
  },
  methods: {
    deletarDoc(doc) {

      this.$swal({
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, quero deletar",
        icon: "warning",
        html: "Tem certeza que deseja deletar o anexo?"
      }).then((result) => {
        if (result.isConfirmed) {

          let json = {
            doc: doc,
            project: this.$route.params.projeto
          }

          this.$http
            .post("user/projects/doc/delete", json, {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer " + this.token
              },
            }).then((response) => {
              this.showAlert('success', response.data.message);
              this.isSearch = false;
              this.load();
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                this.showAlert('error', error.response.data);
              } else {
                this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
              }
            });
        }
      });
    },
    getStatusPay(status) {
      switch (status) {
        case 1:
          return 'Aguardando Pagamento 1/2';
        case 2:
          return 'Pagamento Confirmado 1/2';
        case 3:
          return 'Aguardando Pagamento 2/2';
        case 4:
          return 'Pago';
      }
    },
    statusType(statusValue) {
      switch (statusValue) {
        case 1:
          return 'Aberto';
        case 2:
          return 'Em progresso';
        case 3:
          return 'Protocolado';
        case 4:
          return 'Em Parecer de Acesso';
        case 5:
          return 'Obra em andamento';
        case 6:
          return 'Aprovado';
        case 7:
          return 'Vistoria solicitada';
        case 8:
          return 'Finalizado';
        case 9:
          return 'Cancelado';
        case 10:
          return 'Aguardando Solicitação de Vistoria';
        default:
          return 'Status desconhecido';
      }
    },
    clear() {
      this.project_name = '';
      this.consumer_unit = '';
      this.uc_holder_name = '';
      this.cpf_cnpj = '';
      this.rg = '';
      this.phone = '';
      this.street = '';
      this.address_number = '';
      this.neighborhood = '';
      this.city = '';
      this.state = '';
      this.cep = '';
      this.company_name = '';
      this.observation = '';
      this.has_attachment = '';
      this.complement = '';
      this.type = 0;
      this.is_legal_entity = 0;
      this.status = 0;
      this.payment_status = 1;
      this.is_cpf = true;
    },
    showAlert(type, msg) {
      var textMsg = "";

      if (msg.message) {


        if (typeof msg.errors === 'object' && msg.errors) {
          for (const key in msg.errors) {
            if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(', ')}<br>`;
            }
          }
        } else {
          textMsg = `${msg.message}`;
        }
      } else {
        textMsg = `${msg}`;
      }

      this.$swal({
        icon: type,
        confirmButtonText: 'Confirmar',
        html: textMsg
      });
    },
    amountConvert(value) {
      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    legalEntity() {
      this.is_cpf = !this.is_cpf;
      this.cpf_cnpj = '';
    },
    deleteConfirm() {
      this.$swal({
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, quero deletar",
        icon: "warning",
        html: "Tem certeza que deseja deletar?"
      }).then((result) => {
        if (result.isConfirmed) {
          this.delete();
        }
      });
    },
    delete() {
      this.$http
        .delete("user/projects/" + this.$route.params.projeto, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Bearer " + this.token
          },
        }).then((response) => {
          this.showAlert('success', response.data.message);
          this.$router.push('/projetos/subestacao-eletrica');
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }
        });
    },
    update() {
      if (this.type == 0) {
        return this.showAlert('error', 'Informa o tipo de projeto');
      }

      var json = {
        project_name: this.project_name,
        consumer_unit: this.consumer_unit,
        uc_holder_name: this.uc_holder_name,
        email: this.email,
        cpf_cnpj: this.cpf_cnpj,
        rg: this.rg,
        phone: this.phone,
        street: this.street,
        address_number: this.address_number,
        neighborhood: this.neighborhood,
        city: this.city,
        state: this.state,
        zip_code: this.cep,
        company_name: this.company_name,
        observation: this.observation,
        has_attachment: this.has_attachment,
        complement: this.complement,
        project_type: this.type,
        is_legal_entity: this.is_legal_entity,
        id_state: this.status,
        payment_status: this.payment_status,

        //Financeiro
        amount_project: this.amount_project,
        amount_art_trt: this.amount_art_trt,
        amount_paid: this.amount_paid,

        substation_power: this.substation_power,
        substation_connection_distance: this.substation_connection_distance,

        // anexo_1: this.$refs.anexo_1.files[0],
        // anexo_2: this.$refs.anexo_2.files[0],
        // anexo_3: this.$refs.anexo_3.files[0],
        // anexo_5: this.$refs.anexo_5.files[0],
        // anexo_6: this.$refs.anexo_6.files[0],
        // anexo_7: (this.is_cpf)? null : this.$refs.anexo_7.files[0],
        // anexo_8: (this.is_cpf)? null : this.$refs.anexo_8.files[0],
        // anexo_10: this.$refs.anexo_10.files[0]

      };

      this.$http
        .put("user/projects/" + this.$route.params.projeto, json, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Bearer " + this.token
          },
        }).then((response) => {
          this.showAlert('success', response.data.message);
          this.isSearch = false;
          this.load();
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }
        });
    },
    search() {
      if (!this.cep || this.cep.length < 9) {

        return;
      }

      this.$http.get('/cep/' + this.cep)
        .then((response) => {
          console.log(response);
          this.street = response.data.logradouro;
          this.neighborhood = response.data.bairro;
          this.city = response.data.localidade;
          this.state = response.data.uf;
        }).catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }
        })
    },
    formatDate(type, date) {
      if (!date) {
        return '';
      }

      const dateFormat = new Date(date);

      if (type == 0) {
        return `${dateFormat.getDate().toString().padStart(2, '0')}/${(dateFormat.getMonth() + 1).toString().padStart(2, '0')}/${dateFormat.getFullYear()}`;
      } else {

        return `${dateFormat.getDate().toString().padStart(2, '0')}/${(dateFormat.getMonth() + 1).toString().padStart(2, '0')}/${dateFormat.getFullYear()} às ${dateFormat.getHours().toString().padStart(2, '0')}:${dateFormat.getMinutes().toString().padStart(2, '0')}`;
      }

    },
    load() {

      this.$http.get("user/projects/" + this.$route.params.projeto, {
        headers: {
          "Authorization": "Bearer " + this.token
        },
      }).then((response) => {

        this.project_name = response.data.project.project_name;
        this.consumer_unit = response.data.project.consumer_unit;
        this.uc_holder_name = response.data.project.uc_holder_name;
        this.email = response.data.project.email;
        this.cpf_cnpj = response.data.project.cpf_cnpj;
        this.rg = response.data.project.rg;
        this.phone = response.data.project.phone;
        this.street = response.data.project.street;
        this.address_number = response.data.project.address_number;
        this.neighborhood = response.data.project.neighborhood;
        this.city = response.data.project.city;
        this.state = response.data.project.state;
        this.cep = response.data.project.zip_code;
        this.company_name = response.data.project.company_name;
        this.observation = response.data.project.observation;
        this.has_attachment = response.data.project.has_attachment;
        this.complement = response.data.project.complement;
        this.type = response.data.project.project_type;
        this.is_legal_entity = response.data.project.is_legal_entity;

        this.status = response.data.project.id_state;

        this.attachments = response.data.attachments;

        this.amount_project = response.data.financial.amount_project;
        this.amount_art_trt = response.data.financial.amount_art_trt;
        this.amount_paid = response.data.financial.amount_paid;

        this.substation_power = response.data.project_extra.substation_power;
        this.substation_connection_distance = response.data.project_extra.substation_connection_distance;

        if (this.role == 2) {
          const inputs = this.$el.querySelectorAll('input[readonly]');

          inputs.forEach(input => {
            input.removeAttribute('readonly');
          });
        }


        if (response.data.project.is_legal_entity == 1) {
          this.is_cpf = false;
        }

        this.autoUser();
        this.isSearch = true;

      })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }
        });
    },
    autoUser() {
      if (!this.isSearch) return;

      if (this.is_legal_entity == 0 && this.cpf_cnpj.length < 14 || this.is_legal_entity == 1 && this.cpf_cnpj.length < 18) {
        return;
      }

      var json = {
        'number': this.cpf_cnpj,
        'type': this.is_legal_entity
      }

      this.$http.post('/user/search', json, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": "Bearer " + this.token
        },
      })
        .then((response) => {

          if (!response.data.status) {
            return;
          }


          this.uc_holder_name = response.data.items.full_name;
          this.rg = response.data.items.rg;
          this.phone = response.data.items.phone;
          this.state = response.data.items.state;

          this.integrator = response.data.items.integrators;
          this.is_legal_entity = response.data.items.is_legal_entity;

          if (this.is_legal_entity) {
            this.company_name = response.data.extra.company_name;
          }

          this.address_number = response.data.items.address_number;
          this.street = response.data.items.street;
          this.neighborhood = response.data.items.neighborhood;
          this.city = response.data.items.city;
          this.state = response.data.items.state;
          this.cep = response.data.items.zip_code;
          this.complement = response.data.items.complement;

          const inputs = this.$el.querySelectorAll('input[readonly]');

          inputs.forEach(input => {
            input.removeAttribute('readonly');
          });

        }).catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }
        })
    },
    upload() {
      if (this.role != 2 && this.status != 1) {
        return;
      }

      var json = {
        name: this.anexo_name,
        id_project: this.$route.params.projeto,
        anexo: this.$refs.anexo.files[0],

      };

      this.$http
        .post("user/projects/doc", json, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": "Bearer " + this.token
          },
        })
        .then((response) => {
          //this.clear();
          this.showAlert('success', response.data.message);
          this.isSearch = false;
          this.load();
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }

        });
    },

  },
  created() {
    this.load();
  }
};
</script>
