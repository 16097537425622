<template>
  <div>
    <Sidebar />
    <div class="content">
      <NavBar />
      <main>
        <div class="header">
          <div class="left">
            <h1>Integrador - {{ full_name }}</h1>
          </div>
        </div>
        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class="bx bx-receipt"></i>
              <h3>Informações</h3>
            </div>
            <div class="row">
              <div class="col-md-5 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nome da Integrador
                  </label>
                  <input v-model="full_name" type="text" class="form-control" name="full_name"
                    placeholder="Nome do integrador" />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    CPF
                  </label>
                  <input v-model="cpf" type="text" class="form-control" name="cpf_cnpj" placeholder="000.000.000.-00"
                    v-maska="'###.###.###-##'" />
                </div>
              </div>
              <div class="col-md-2 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    RG
                  </label>
                  <input v-model="rg" type="text" class="form-control" name="rg" placeholder="0123456" />
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Será Empresa?
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="is_legal_entity" @change="legalEntity">
                      <option value="0">Não</option>
                      <option value="1">Sim</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    E-mail
                  </label>
                  <input v-model="email" type="email" class="form-control" name="email" placeholder="teste@teste.com" />
                </div>
              </div>
              <div v-if="!is_cpf" class="col-md-5 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nome da Empresa
                  </label>
                  <input v-model="company_name" type="text" class="form-control" name="company_name"
                    placeholder="Razão Social" />
                </div>
              </div>
              <div v-if="!is_cpf" class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    CNPJ
                  </label>
                  <input v-model="cnpj" type="text" class="form-control" name="cnpj" placeholder="01.001.001/0001-01" />
                </div>
              </div>

              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Telefone
                  </label>
                  <input v-maska="'(##) #####-####'" v-model="phone" class="form-label" />
                </div>
              </div>
              <div class="col-md-2 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    CEP
                  </label>
                  <input v-maska="'#####-###'" v-model="cep" class="form-label" @change="search" />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Cidade
                  </label>
                  <input v-model="city" type="text" class="form-control" name="city" placeholder="Aguardando..." />
                </div>
              </div>
              <div class="col-md-2 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Estado
                  </label>
                  <input v-model="state" type="text" class="form-control" name="state" placeholder="Aguardando..." />
                </div>
              </div>
              <div class="col-md-2 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Numero
                  </label>
                  <input v-model="address_number" type="text" class="form-control" name="address_number"
                    placeholder="Ex: 10" />
                </div>
              </div>
              <div class="col-md-5 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Logradouro
                  </label>
                  <input v-model="street" type="text" class="form-control" name="street" placeholder="Aguardando..." />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Bairro
                  </label>
                  <input v-model="neighborhood" type="text" class="form-control" name="neighborhood"
                    placeholder="Aguardando..." />
                </div>
              </div>

              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Complemento
                  </label>
                  <input v-model="complement" type="text" class="form-control" name="complement"
                    placeholder="Ex: Casa, APT, Sítio, etc" />
                </div>

              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Status
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="is_active">
                      <option value="0">Inativo</option>
                      <option value="1">Ativo</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    E-mail Verificado?
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="id_account_status">
                      <option value="1">Não</option>
                      <option value="2">Sim</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-between mt-5 gap-3">
              <button class="btn btn-danger" @click="deleteConfirm">
                <span>Deletar</span>
              </button>
              <button class="btn btn-success btn-all" @click="update">
                <span>Alterar</span>
              </button>
            </div>
          </div>
        </div>
        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class="bx bx-file"></i>
              <h3>Valores por Projeto</h3>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <span class="form-label ">
                  <h6 class="pl-10 mb-3">Adicionar valor</h6>
                </span>
              </div>
              <div class="col-md-2 col-4">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Potência Minima
                  </label>
                  <input v-model="p_min" type="number" class="form-control" name="street" placeholder="Aguardando..."
                    step="0.00" />
                </div>
              </div>
              <div class="col-md-2 col-4">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Potência Maxima
                  </label>
                  <input v-model="p_max" type="number" class="form-control" name="street" placeholder="Aguardando..."
                    step="0.00" />
                </div>
              </div>
              <div class="col-md-2 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Valor do Projeto
                  </label>
                  <money3 v-model="amount_project" v-bind="config"></money3>
                </div>
              </div>
              <div class="col-md-2 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Valor da RRT
                  </label>
                  <money3 v-model="amount_rrt" v-bind="config"></money3>
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input">
                  <label for="formGroupExampleInput" class="form-label"></label>
                </div>
                <button class="btn btn-primary btn-all" @click="addFee">
                  <span>Adicionar</span>
                </button>
              </div>
            </div>
            <table class="mt-4">
              <thead>
                <tr>
                  <th>Potência Minima</th>
                  <th>Potência Maxima</th>
                  <th>Valor do Projeto</th>
                  <th>Valor do RRT</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="fee in fee_projects" :key="fee.id">
                  <td>{{ fee.p_min }} kWp</td>
                  <td>{{ fee.p_max }} kWp</td>
                  <td>{{ amountConvert(fee.amount_project) }}</td>
                  <td>{{ amountConvert(fee.amount_rrt) }}</td>
                  <td class="actions-icon">
                    <span data-bs-toggle="tooltip" tooltip-bs-title="Deletar item"
                      class="bx bx-trash ml-5 mr-5 icon-primary" @click="deleteFeeConfirm(fee.id)">
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class="bx bx-receipt"></i>
              <h3>Alterar senha</h3>
            </div>
            <div class="row">
              <div class="col-md-6 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label"> Nova senha </label>
                  <input v-model="new_password" type="password" class="form-control" name="passnew"
                    placeholder="Digite a nova senha." />
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label"> Repetir nova senha </label>
                  <input v-model="renew_password" type="password" class="form-control" name="repassnew"
                    placeholder="Repita a  nova senha." />
                </div>
              </div>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-end mt-5">
              <button class="btn btn-primary btn-all" @click="chance">
                <span>Alterar</span>
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>

  </div>
</template>

<script>
import Cookie from 'js-cookie'
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/SideBar.vue";

export default {
  components: {
    NavBar,
    Sidebar,
  },
  data() {
    return {
      full_name: '',
      cpf: '',
      rg: '',
      is_legal_entity: 0,
      email: '',
      phone: '',
      cep: '',
      address_number: '',
      street: '',
      neighborhood: '',
      city: '',
      state: '',
      company_name: '',
      complement: '',
      cnpj: '',
      is_cpf: true,
      is_active: 0,
      id_account_status: 1,
      fee_projects: '',
      p_min: 0,
      p_max: 0,
      amount_project: 0,
      amount_rrt: 0,
      config: {
        masked: false,
        prefix: 'R$ ',
        suffix: '',
        thousands: '.',
        decimal: ',',
        precision: 2,
        disableNegative: false,
        disabled: false,
        min: null,
        max: null,
        allowBlank: false,
        minimumNumberOfCharacters: 0,
        shouldRound: true,
        focusOnRight: false,
      },
      token: Cookie.get('_token'),
    };
  },
  methods: {
    showAlert(type, msg) {
      var textMsg = "";

      if (msg.message) {


        if (typeof msg.errors === 'object' && msg.errors) {
          for (const key in msg.errors) {
            if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(', ')}<br>`;
            }
          }
        } else {
          textMsg = `${msg.message}`;
        }
      } else {
        textMsg = `${msg}`;
      }

      this.$swal({
        icon: type,
        confirmButtonText: 'Confirmar',
        html: textMsg
      });
    },
    amountConvert(value) {
      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    legalEntity() {
      this.is_cpf = !this.is_cpf;
    },
    chance() {
      if (this.new_password != this.renew_password) {
        this.showAlert("error", "A nova senha e a confirmação não estão iguais.");
        return;
      }

      var json = {
        old: this.old_password,
        new: this.new_password,
        renew: this.renew_password,
      }

      this.$http.post("user/integrators/change/" + this.$route.params.user, json, {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          this.showAlert("success", response.data.message);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },
    loadFee() {
      this.$http
        .get("user/integrators/fee/" + this.$route.params.user, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Bearer " + this.token
          },
        }).then((response) => {
          this.fee_projects = response.data.items;
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }
        });
    },
    addFee() {
      var json = {
        p_min: parseFloat(this.p_min).toFixed(2),
        p_max: parseFloat(this.p_max).toFixed(2),
        amount_project: this.amount_project,
        amount_rrt: this.amount_rrt,
        integrator: this.$route.params.user
      }

      this.$http.post("user/integrators/fee", json, {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          this.showAlert("success", response.data.message);
          this.loadFee();
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },
    deleteFeeConfirm(item) {
      this.$swal({
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, quero deletar",
        icon: "warning",
        html: "Tem certeza que deseja esse item?"
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteFee(item);
        }
      });
    },
    deleteFee(item) {
      this.$http
        .delete("user/integrators/fee/" + item, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Bearer " + this.token
          },
        }).then((response) => {
          this.showAlert('success', response.data.message);
          this.loadFee();
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }
        });
    },
    deleteConfirm() {
      this.$swal({
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, quero deletar",
        icon: "warning",
        html: "Tem certeza que deseja deletar?"
      }).then((result) => {
        if (result.isConfirmed) {
          this.delete();
        }
      });
    },
    delete() {
      this.$http
        .delete("user/integrators/" + this.$route.params.user, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Bearer " + this.token
          },
        }).then((response) => {
          this.showAlert('success', response.data.message);
          this.$router.push('/integradores');
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }
        });
    },
    update() {

      var json = {
        full_name: this.full_name,
        cpf: this.cpf,
        rg: this.rg,
        email: this.email,
        phone: this.phone,
        street: this.street,
        address_number: this.address_number,
        neighborhood: this.neighborhood,
        city: this.city,
        state: this.state,
        cep: this.cep,
        company_name: this.company_name,
        complement: this.complement,
        is_legal_entity: this.is_legal_entity,
        is_active: this.is_active,
        id_account_status: this.id_account_status,
        cnpj: this.cnpj,
        p_min: this.p_min,
        p_max: this.p_max,
        amount_project: this.amount_project,
        amount_rrt: this.amount_rrt,
      };

      this.$http
        .put("user/integrators/" + this.$route.params.user, json, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Bearer " + this.token
          },
        })
        .then((response) => {
          this.showAlert('success', response.data.message);
          this.load();
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }

        });
    },
    search() {
      if (!this.cep || this.cep.length < 9) {

        return;
      }

      this.$http.get('/cep/' + this.cep)
        .then((response) => {
          console.log(response);
          this.street = response.data.logradouro;
          this.neighborhood = response.data.bairro;
          this.city = response.data.localidade;
          this.state = response.data.uf;
        }).catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }
        })
    },
    load() {
      this.$http
        .get("user/integrators/" + this.$route.params.user, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Bearer " + this.token
          },
        })
        .then((response) => {
          if (response.data.items.is_legal_entity == 1) {

            if (this.is_cpf) {
              this.is_cpf = !this.is_cpf;
            }

            this.company_name = response.data.items.company_name;
          }

          this.full_name = response.data.items.full_name;
          this.cpf = response.data.items.cpf;
          this.rg = response.data.items.rg;
          this.email = response.data.items.email;
          this.phone = response.data.items.phone;
          this.street = response.data.items.street;
          this.address_number = response.data.items.address_number;
          this.neighborhood = response.data.items.neighborhood;
          this.city = response.data.items.city;
          this.state = response.data.items.state;
          this.cep = response.data.items.zip_code;
          this.complement = response.data.items.complement;
          this.is_legal_entity = response.data.items.is_legal_entity;
          this.is_active = response.data.items.is_active;
          this.id_account_status = response.data.items.id_account_status;

          this.fee_projects = response.data.items.fee_projects;

          if (response.data.items.is_legal_entity == 1) {
            this.cnpj = response.data.extra.cnpj;
            this.company_name = response.data.extra.company_name;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }

        });
    },
  },
  created() {
    this.load();
    this.loadFee();
  }
};
</script>
