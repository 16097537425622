<template>
    <div>
        <section v-if="!isPayment" class="pricing-section">

            <div class="container">
                <h1 class="title">Plano de Assinatura</h1>
                <p class="description">Para continuar a usar o sistema você deve realizar uma assinatura.</p>
                <div class="separator"></div>

                <ul class="plans">
                    <li class="item-plan popular" v-for="plan in plans" :key="plan.id">
                        <h2 class="card-title">{{ plan.plan }} </h2>
                        <p class="card-price">{{ amountConvert(plan.amount) }}</p>
                        <p class="card-description">você terá acesso a diversas ferramentas por {{ plan.day }} dias!</p>
                        <ul class="features">
                            <li>Gerenciamento de projetos</li>
                            <li>Gerenciamento de integradores</li>
                            <li>Configuração de procuração</li>
                            <li>Configuração de taxas</li>
                            <li>E muito mais</li>
                        </ul>
                        <button class="btn btn-primary btn-all mt-5" @click="selectPlan(plan.id)">Escolher
                            plano</button>
                    </li>
                </ul>
            </div>
        </section>
        <section v-else class="payment">

            <div class="container">
                <h1 class="title">Realizar Pagamento</h1>
                <p class="description">Para concluir sua assinatura, realize o pagamento</p>
                <div class="separator"></div>
                <div class="box-payment mt-2 wow fadeInUp" data-wow-duration="0.8s">
                    <div class="box-pay">
                        <div class="qrcode">

                            <div class="code">
                                <img :src="payment.pix.imagemQrcode" />
                            </div>
                            <div class="copy">
                                <input id="pix-url" type="text" v-model="payment.pix.qrcode" readonly>
                                <label class="btn btn-primary btn-all" @click="copy">Copiar</label>
                            </div>
                        </div>
                        <div class="info-ticket">
                            <label class="title">Detalhes da sua Compra</label>
                            <label class="code-pay mb-3">Identificador de pagamento: {{ payment.code }}</label>
                            <label class="">Data/horário: {{ formatDate(payment.data) }}</label>
                            <label class="">Situação: {{ getStatusText(0) }}</label>
                            <label class="details">
                                <p class="mt-3 mb-3">Pagar com o Pix é fácil e rápido!</p>
                                <span>1 - Abra o seu aplicativo Pix do seu Banco ou empresa de pagamento.</span>
                                <span>2 - Clique em Pagar com Pix/Pix QRCode e aponte a câmera para o QR Code
                                    PIX.</span>
                                <span>3 - Pronto, agora é so finalizar o pagamento e aguardar a Aprovação.</span>
                                <span>4 Se preferir, Copie e Cole o Código PIX no seu Aplicativo ou Site PIX</span>
                                <span class="mt-2">Após a aprovação, você poderá usar a plataforma</span>
                            </label>
                            <label class="info-amout">
                                <span class="amout">Total: {{ amountConvert(payment.amount) }}</span>
                            </label>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    </div>
</template>

<script>
import Cookies from 'js-cookie';

export default {
    components: {

    },
    data() {
        return {
            plans: [],
            payment: [],
            isPayment: false,
            intervalId: null,
            token: Cookies.get('_token'),
        }
    },
    methods: {
        showAlert(type, msg) {
            var textMsg = "";

            if (msg.message) {
                if (typeof msg.errors === 'object' && msg.errors) {
                    for (const key in msg.errors) {
                        if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
                            const errorMessages = msg.errors[key];
                            textMsg += `${errorMessages.join(', ')}<br>`;
                        }
                    }
                } else {
                    textMsg = `${msg.message}`;
                }
            } else {
                textMsg = `${msg}`;
            }

            this.$swal({
                text: textMsg,
                icon: type,
                confirmButtonText: 'Confirmar',
                html: textMsg
            });
        },
        copy() {
            let text = document.getElementById("pix-url");
            text.select();
            text.setSelectionRange(0, 99999)

            if (!navigator.clipboard) {
                document.execCommand("copy")
                    .then(() => {
                        this.showAlert('success', 'O código do PIX foi copiado!');
                    })
                    .catch(() => {
                        this.showAlert('error', 'Erro ao copiar o código do PIX')
                    });
            } else {
                navigator.clipboard.writeText(text.value)
                    .then(() => {
                        this.showAlert('success', 'O código do PIX foi copiado!');
                    })
                    .catch(() => {
                        this.showAlert('error', 'Erro ao copiar o código do PIX')
                    });
            }


        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const day = date.getDate();
            const month = date.getMonth() + 1; // Os meses começam em 0, por isso adicionamos 1
            const year = date.getFullYear();
            const hours = date.getHours();
            const minutes = date.getMinutes();

            const formattedDate = `${day}/${month}/${year} às ${hours}h${minutes}`;
            return formattedDate;
        },
        getStatusText(status) {
            switch (status) {
                case 0: // completed
                    return 'Aguardando pagamento';
                case 1: // pending
                    return 'Pagamento realizado';
                case 2: // cancelled
                    return 'Pagamento cancelado';
            }
        },
        amountConvert(value) {
            return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        },
        load() {
            this.$http.get("/user/plans", {
                headers: {
                    Authorization: "Bearer " + this.token,
                },
            })
                .then((response) => {
                    this.plans = response.data.plans;
                })
                .catch((error) => {
                    if (error.response && error.response.data) {
                        this.showAlert('error', error.response.data);
                    } else {
                        this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
                    }
                });
        },
        selectPlan(id) {
            this.$swal({
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, quero",
                icon: "warning",
                html: "Você deseja assinar esse plano?",
                showLoaderOnConfirm: true,
                preConfirm: async () => {

                    try {

                        let callback;

                        let json = {
                            plan: id
                        }

                        await this.$http.post("/user/plans", json, {
                            headers: {
                                Authorization: "Bearer " + this.token,
                            },
                        }).then((response) => {
                            callback =  response.data;

                        }).catch((error) => {
                            if (error.response && error.response.data) {
                                this.showAlert('error', error.response.data);
                            } else {
                                this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
                            }
                        });

                        return callback;
                       
                    } catch (error) {
                        this.$swal.showValidationMessage(`Request failed: ${error}`);
                    }
                },
                allowEscapeKey: false,  
                allowOutsideClick: () => !this.$swal.isLoading()
            }).then((result) => {
                if (result.isConfirmed) {
                    this.showAlert('success', result.value.message);
                    this.payment = result.value.item
                    this.isPayment = true;
                }
            });
        },
        startPlanCheck() {
            //if(!this.isPayment) return;

            this.intervalId = setInterval(() => {
                this.plancheck();
            }, 10000); 
        },
        stopPlanCheck() {
            if (this.intervalId) {
                clearInterval(this.intervalId);
                this.intervalId = null;
            }
        },
        plancheck() {
            this.$http
                .get("/user/planCheck", {
                    headers: {
                        Authorization: "Bearer " + this.token,
                    },
                })
                .then((response) => {
                    if (!response.data.status) return;

                    this.showAlert("success", "Sua assinatura foi concluida com sucesso!");
                    this.stopPlanCheck();

                    this.$store.dispatch('setUser', response.data.user);

                    this.$router.push('/');
 
                })
                .catch((error) => {
                    if (error.response && error.response.data) {
                        this.showAlert("error", error.response.data);
                    } else {
                        this.showAlert(
                            "error",
                            "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
                        );
                    }
                });
        },
    },
    created() {
        this.load();
    },
    mounted() {
        this.startPlanCheck();
    },
    unmounted() {
        this.stopPlanCheck();
    },
    computed: {

    },
}
</script>

<style scoped>
.pricing-section,
.payment {
    margin: 30px 0;
}

.title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 10px;
}

.description {
    text-align: center;
    font-size: 1rem;
    color: #6b7280;
}

.separator {
    width: 100px;
    height: 4px;
    background-color: var(--primary);
    margin: 20px auto;
    border-radius: 2px;
}


.pricing-section .card-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.pricing-section .card-price {
    font-size: 25px;
    color: var(--dark);
    font-weight: 600;
    margin-bottom: 15px;
}

.pricing-section .card-description {
    text-align: center;
    width: 85%;
    font-size: 0.9rem;
    color: #6b7280;
    margin-bottom: 20px;
}


.pricing-section .plans {
    display: flex;
    justify-content: center;
    gap: 25px;
    padding: 0;
    margin-top: 36px;
}

.pricing-section .plans .item-plan,
.payment .box-payment {
    padding: 24px;
    background: var(--light);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.pricing-section .plans .item-plan {
    width: calc(33.33% - 25px);
}

.pricing-section .features li::before {
    content: '\2713';
    color: #22c55e;
    font-weight: bold;
    margin-right: 10px;
}

.pricing-section .features {
    list-style: none;
    padding: 0;
}

.pricing-section .features li {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: var(--dark) !important;
}


.payment .box-payment {
    width: 100%;
}

.payment .box-payment .box-pay {
    width: 100%;
    display: flex;
    gap: 50px;
    height: inherit;
}

.payment .box-payment .box-pay .qrcode,
.payment .box-payment .box-pay .info-ticket {
    width: calc(100% - 50px);
}

.payment .box-payment .box-pay .qrcode {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.payment .box-payment .box-pay .qrcode .time span {
    font-size: 30px;
    font-weight: 600;
    color: var(--color-4);
}

.payment .box-payment .box-pay .qrcode .code {
    display: flex;
    justify-content: center;
    width: 410px;
    height: 330px;
    margin-top: 20px;
}

.payment .box-payment .box-pay .qrcode .code img {
    width: 310px;
    height: 310px;
}

.payment .box-payment .box-pay .qrcode .list {
    display: flex;
    align-items: self-start;
    flex-wrap: wrap;
    gap: 5px;
    width: 100%;
    max-height: 410px;
    padding-right: 20px;
    overflow: hidden;
    overflow-y: auto;
}

.payment .box-payment .box-pay .qrcode .list label {
    width: calc(14% - 5px);
    padding: 2px;
    font-size: 15px;
    border-radius: 5px;
    text-transform: uppercase;
    background-color: var(--color-4);
}

.payment .box-payment .box-pay .qrcode .copy {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.payment .box-payment .box-pay .qrcode .copy .btn {
    width: 300px;
}

.payment .box-payment .box-pay .qrcode .copy input {
    padding: 10px;
    border-radius: 5px;
    border: 2px solid var(--primary);
    color: var(--color-12);
    outline: 3px solid transparent;
    background-color: transparent;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.5;
    min-width: -moz-max-content;
    transition: all 0.35s ease-in-out;
    width: 300px;
}

.payment .box-payment .box-pay .info-ticket {
    display: flex;
    flex-direction: column;
    align-items: self-start;
}

.payment .box-payment .box-pay .qrcode .time,
.payment .box-payment .box-pay .info-ticket .title {
    font-size: 25px;
    font-weight: 600;
    color: var(--color-12);
}

.payment .box-payment .box-pay .info-ticket .code-pay {
    font-size: 13px;
    margin-bottom: 30px;
}

.payment .box-payment .box-pay .info-ticket .details {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    line-height: 15px;
    text-align: left;
}

.payment .box-payment .box-pay .info-ticket .details span {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 15px;
    font-size: 14px;
    color: var(--color-4);
}

.payment .box-payment .box-pay .info-ticket .details span::before {
    position: absolute;
    content: "";
    width: 5px;
    height: 5px;
    left: 5px;
    border-radius: 50%;
    background-color: var(--color-4);
}

.payment .box-payment .box-pay .info-ticket .info-amout {
    width: 100%;
    padding: 20px 0;
    border-radius: 15px;
    margin-top: 20px;
    text-transform: uppercase;
    color: var(--light);
    background-color: var(--primary);

}

.payment .box-payment .box-pay .info-ticket .info-amout .amout {
    color: var(--color-12);
    font-size: 25px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
