<template>
    <main class="login">
        <div class="box">
            <div class="title">
                <img v-if="!darkMode" class="logo-name" src="@/assets/images/logo_inoveng.png" alt="Logo">
                <img v-if="darkMode" class="logo-name" src="@/assets/images/logo_inoveng_white.png" alt="Logo">
            </div>
            <div class="inputs-login">
                <div class="form-input mb-2">
                    <label for="formGroupExampleInput" class="form-label">E-mail</label>
                    <input v-model="email" type="text" class="form-control" placeholder="Digite o e-mail de acesso">
                </div>
                <div class="form-input">
                    <label for="formGroupExampleInput" class="form-label">Senha</label>
                    <input v-model="password" type="password" class="form-control" placeholder="Digite sua senha">
                </div>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-center">
                <button href="#" class="btn btn-primary btn-all w-100" @click="auth">Acessar</button>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-center">
                <router-link to="registro" class="btn btn-all-2 w-100">Registre-se</router-link>
            </div>
        </div>
    </main>
</template>

<script>
import Cookie from 'js-cookie'
export default {

    data() {
        return {
            email: '',
            password: '',
            darkMode: (Cookie.get('theme') == 'true')? true : false,
        }
    },
    methods: {
        clear() {
            this.email = "";
            this.password = "";
        },
        showAlert(type, msg) {
            var textMsg = "";

            if (msg.message) {
                if (typeof msg.errors === 'object' && msg.errors) {
                    for (const key in msg.errors) {
                        if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
                            const errorMessages = msg.errors[key];
                            textMsg += `${errorMessages.join(', ')}<br>`;
                        }
                    }
                } else {
                    textMsg = `${msg.message}`;
                }
            } else {
                textMsg = `${msg}`;
            }

            this.$swal({
                text: textMsg,
                icon: type,
                confirmButtonText: 'Confirmar',
                html: textMsg
            });
        },
        auth() {
            var json = {
                email: this.email,
                password: this.password,
            };

            this.$http
            .post("/login", json, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            })
            .then((response) => {

                Cookie.set('_token', response.data.user.token);
                
                this.$store.dispatch('setRoleOnLogin', response.data.user.role);
                this.$store.dispatch('setUser', response.data.user);

                if(!Cookie.get('theme')){
                    Cookie.set('theme', false)
                }

                Cookie.set('link', response.data.user.code);

                setTimeout(() => {
                    if(response.data.user.status == 0){
                        this.$router.push('/planos');
                    }else{
                        this.$router.push('/');
                    }
                }, 1000);

            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    this.showAlert('error', error.response.data);
                } else {
                    this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
                }
            });
        }
    },
    created() {
        if(Cookie.get('_token')){
            Cookie.remove('_token');
        }
    }
}
</script>
